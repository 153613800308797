<nb-card class="dialog-modal-card" *ngIf="title && body">
    <nb-card class="dialog-modal-card" >
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
        <p>{{ body }}</p>
        <p class="storage-available"><strong>{{ availableText }}</strong> {{ availableValue }}</p>
        <p>{{ toUpgradeText }} <a href='mailto:info@quicktelradios.com'>info@quicktelradios.com</a></p>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton status="success" (click)="dismiss()">OK</button>
    </nb-card-footer>
</nb-card>