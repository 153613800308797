import { Component, Input,ViewChild, OnDestroy } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NbDialogRef } from '@nebular/theme';
import { LuanchertServices } from '../../../luancher/luancher.services';

@Component({
    selector: "dialog-pin-login",
    styleUrls: ["./dialog-pin-login.component.scss"],
    templateUrl: "./dialog-pin-login.component.html",
})
export class DialogPinLoginComponent implements OnDestroy {
    
    @Input() token : string;

    @ViewChild("form") form: any;

    _focus: boolean = false;

    duration: number = 60; 
    logOnAuthentication: any = {
        pincode:''
    };
    modalCloseAction: any;
    submitted: boolean = false;

    subscribeInterval: any;
    clockDisplay: string;
    loading: boolean = false;

    ngOnDestroy(): void {

        this.submitted = false;

        if (this.subscribeInterval) {

            clearInterval(this.subscribeInterval);
        }
    }

    constructor(protected ref: NbDialogRef<DialogPinLoginComponent>,
        private _luanchertServices:LuanchertServices,
        private _toastr: ToastrService) {

        this.clockDisplay = this.formatDisplay(this.duration);

        this.timerTick();
    }

    private closeModal(result: any) {
        this.ref.close(result);
    }

    onLogOn(formData: any, isValid: boolean): void {

        if (!isValid) {

            this.submitted = true;
            return;
        }

        let data = {
            token: this.token,
            pincode: formData.pincode
        };

        this.loading = true;

        this._luanchertServices.LoginByPin(data).subscribe(res => {
            
            this.loading = false;

            if (res) {
                if (res.status) {
                    this.closeModal(res);
                }else {

                    switch(res.message) { 
                        case 'INVALIDPASSWORD':{
                          this._toastr.error("Incorrect pin code! Please try again");
                          break;
                        }
                        case 'MAXFAILEDLOGIN':{
                          this.closeModal({ status:false,message:'MAXFAILEDLOGIN' });
                          break;
                        }
                        case 'INACTIVEUSER':{
                          this.closeModal({ status:false,message:'INACTIVEUSER' });
                          break;
                        }
                        default:{
                          this._toastr.error("Unable to login! An unexpected error occurred.");
                          this.closeModal({ status:false });
                          break;
                        }
                      }
                }
            }
        });
    }

    onExit() {

        this.closeModal({ status: false });
    }

    formatDisplay(value: number): string {

        let minutes: number = Math.floor(value / 60);
        let seconds: number = (value - minutes * 60);

        return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    }

    private timerTick() {

        this.subscribeInterval = setInterval(() => {

            if (this.duration > 0) {

                this.clockDisplay = this.formatDisplay(--this.duration);

            } else {

               this.closeModal({ status:false });
            }

        }, 1000);
    }
}
