<div class="modal-content dialog-modal-card">
    <div class="modal-header">
        <h4 class="modal-title">Enter PIN Code</h4>
    </div>
    <form #form="ngForm" class="form-horizontal" novalidate (ngSubmit)="onLogOn(form.value, form.valid)" autocomplete="off">
        <div class="modal-body">
            <div class="form-group" [ngClass]="{ 'has-error': (submitted && !pincode.valid) || (pincode.touched && !pincode.valid) }">               
                <pincode length="6" autocomplete="off" id="pincode" autocomplete="off" name="pincode" focus="true" required [(ngModel)]="logOnAuthentication.pincode" #pincode="ngModel"></pincode>
                <div class="alert alert-danger pin" *ngIf="(submitted && !pincode.valid) || (pincode.touched && !pincode.valid)" style="position: absolute; z-index: 100;">
                    <div *ngIf="pincode.errors.invalid">PIN code value is incomplete!</div>
                    <div *ngIf="pincode.errors.required">PIN code is required</div>
                </div>
            </div>
        </div>
        <div class="modal-footer row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6 timer">
                        Time: <span>{{ clockDisplay }}</span>
                    </div>
                    <div class="col-md-6 right">
                        <button type="submit" class="btn btn-primary confirm-btn">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<loading *ngIf="loading" message="Loading..."></loading>