import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    selector: 'dialog-validation.component',
    templateUrl: './dialog-validation.component.html',
    styleUrls: ['./dialog-validation.component.scss'],
  })
export class DialogValidationComponent {

  @Input() title: string;
  @Input() body: string;
  @Input() availableText: string;
  @Input() toUpgradeText: string;

  constructor(protected ref: NbDialogRef<DialogValidationComponent>) {
  }

  dismiss() {
    this.ref.close();
  }
}