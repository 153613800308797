import { Component, Input,ViewChild, OnDestroy } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NbDialogRef } from '@nebular/theme';
import { PagesServices } from '../../pages.services';
import { UserManagementService } from '../../user-management/user-management.services';

@Component({
    selector: "dialog-share-liveview-request",
    styleUrls: ["./dialog-share-liveview-request.scss"],
    templateUrl: "./dialog-share-liveview-request.html",
})
export class  DialogShareLiveviewRequestComponent implements OnDestroy {

    @ViewChild("form") form: any;
    @Input() device: any;
    modalCloseAction: any;
    submitted: boolean = false;

    loading: boolean = false;
    _userNameList:any;
    _selectUser: any = "";

    ngOnDestroy(): void {

        this.submitted = false;
    }

    constructor(protected ref: NbDialogRef<DialogShareLiveviewRequestComponent>,
        private _pagesServices : PagesServices) {

            this._userNameList =  this._pagesServices.deviceUsers.filter(q=>q.user_type == 'DISPATCH' && q.id != this._pagesServices.GetUserUUId());
            if(  this._userNameList.length > 0){
                this._selectUser = this._userNameList[0].device;
            }else{
                this.closeModal({ status: false });
            }

    }

    private closeModal(result: any) {
        this.ref.close(result);
    }

    dismiss() {
        this.closeModal({ status: false });
    }

    sendRequest(){
        console.log(this._selectUser);
        this.closeModal({status: true,receiver:this._selectUser});
    }

}
