<div class="modal-content dialog-modal-card">
    <div class="modal-header">
        <h4 class="modal-title">Change PIN Code</h4>
    </div>
    <form #form="ngForm" class="form-horizontal" novalidate (ngSubmit)="onSubmit(form.value, form.valid)" autocomplete="off">
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <label for="password">Password:</label>
                    <div class="form-group" [ngClass]="{'has-error': password.touched && password.errors}">
                        <input type="password" autocomplete="off" class="form-control" id="password" name="password" nbInput fullWidth  minlength="8" maxlength="50"
                          placeholder="Password" ngModel required #password="ngModel"  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}" 
                          [(ngModel)]="data.password" reverse="true" autocomplete="off"/>
                        <div class="input alert alert-danger" *ngIf="(submitted && !password.valid) || (password.touched && !password.valid)">
                          <div *ngIf="password.errors.required">Password is required</div>
                          <div *ngIf="password.errors.minlength">Password should be minimum 8 characters.</div>
                          <div *ngIf="password && password.errors.pattern">Password must contain minimum one upper case letter, one lower case letter, one number and one special character ! @ # $ % ^ & * ( ) _ +</div>
                        </div>
                      </div>
                </div>
                <div class="col-12">
                    <div class="form-group" [ngClass]="{ 'has-error': (submitted && !pincode.valid) || (pincode.touched && !pincode.valid) }">        
                        <label for="pin">PIN Code:</label>       
                        <pincode length="6" autocomplete="off" id="pincode" autocomplete="off" name="pincode" focus="true" required [(ngModel)]="data.pincode" #pincode="ngModel"></pincode>
                        <div class="alert alert-danger pin" *ngIf="(submitted && !pincode.valid) || (pincode.touched && !pincode.valid)" style="position: absolute; z-index: 100;">
                            <div *ngIf="pincode.errors.invalid">PIN code value is incomplete!</div>
                            <div *ngIf="pincode.errors.required">PIN code is required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
         
                    </div>
                    <div class="col-md-6 right">
                        <button type="submit" nbButton status="primary">Submit</button>
                        <button nbButton status="danger" (click)="onExit()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>