import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent {

  @Input() title: string;
  @Input() body: string;
  @Input() btnAccept: string = 'Yes';
  @Input() btnCancel: string = 'No';
  @Input() size: string = 'sm';
  
  constructor(protected ref: NbDialogRef<DialogConfirmComponent>) {
  }

  dismiss(result:any) {
    this.ref.close(result);
  }

}