import { Component, Input,ViewChild, OnDestroy } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NbDialogRef } from '@nebular/theme';
import { PagesServices } from '../../pages.services';
import { UserManagementService } from '../../user-management/user-management.services';

@Component({
    selector: "dialog-pin-liveview-restricted",
    styleUrls: ["./dialog-pin-liveview-restricted.scss"],
    templateUrl: "./dialog-pin-liveview-restricted.html",
})
export class DialogPinLiveviewRestrictedComponent implements OnDestroy {

    @ViewChild("form") form: any;
    @Input() device: any;
    logOnAuthentication: any = {
        username:'',
        pincode:''
    };
    modalCloseAction: any;
    submitted: boolean = false;

    loading: boolean = false;
    _userNameList:any;
    isAdmin: boolean = false;
    _modelist:any = [
        {
            value:'request',
            name:'Send Request',
            checked:true
        },
        {
            value:'pin',
            name:'PIN Code',
            checked:false
        }
    ];

    _mode = "request";
    ngOnDestroy(): void {

        this.submitted = false;
    }

    constructor(protected ref: NbDialogRef<DialogPinLiveviewRestrictedComponent>,
        private _toastr: ToastrService,
        private _pagesServices : PagesServices,
        private _userManagementService : UserManagementService) {
            this._userManagementService.GetListAdminUsers().subscribe(res=>{

                if(res.status){
                    this._userNameList = res.data;
                    this.logOnAuthentication.username = res.data[0].username;
                }else{
                    this._toastr.error("Dispatch Administrator not found");
                    this.dismiss();
                }
            });

            this.isAdmin = JSON.parse(localStorage.getItem("currentUser")).is_admin;
            if(this.isAdmin){
                this._mode = "admin";
            }
    }

    private closeModal(result: any) {
        this.ref.close(result);
    }

    onSubmit(formData: any, isValid: boolean): void {

        if (!isValid) {
            this.submitted = true;
            return;
        }

        this.loading = true;
        this._pagesServices.PinAuthorize(formData.username,formData.pincode).subscribe(res=>{
            this.loading = false;
            if(res.status){
                this.closeModal({status: true,mode: this._mode,admin:this.logOnAuthentication.username});
            }else{
                switch(res.message){
                    case "USERNAMENOTEXIST":
                        this._toastr.error("Incorrect username or pin code.");
                    break;
                    case "INCORRECTPINCODE":
                        this._toastr.error("Incorrect username or pin code.");
                    break;
                    case "INACTIVEUSER":
                        this._toastr.error("Authorization failed! User is inactive.");
                    break;
                    case "INVALIDUSER":
                        this._toastr.error("Authorization failed! Invalid user.");
                    break;
                    case "NOPERMISSION":
                        this._toastr.error("Authorization failed! Insufficient permissions.");
                    break;
                    default:
                        this._toastr.error("Authorization failed! An unexpected error has occurred.");
                    break;
                }
            }

        });

    }

    dismiss() {
        this.closeModal({ status: false });
    }


    sendRequest(){
        this.closeModal({status: true,mode: this._mode,admin:this.logOnAuthentication.username});
    }
    
    pinfocus =true;
    changeMode(){
        if(this._mode  == 'pin'){
            document.getElementById("pincode0").focus();
            // this.pinfocus = true;
    
        }else{
            // this.pinfocus = false;
            document.getElementById("btnSend").focus();
        }
        
    }

    adminRequest(){
        this.closeModal({status: true,mode: this._mode}); 
    }

}
