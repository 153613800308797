import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./luancher/login/login.module')
      .then(m => m.LoginModule),
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./luancher/maintenance/maintenance.module')
      .then(m => m.MaintenanceModule),
  },
  {
    path: 'request-password',
    loadChildren: () => import('./luancher/forgot-password/forgot-password.module')
      .then(m => m.ForgotPasswordModule),
  },
  {
    path: 'verify-otp',
    loadChildren: () => import('./luancher/verify-otp/verify-otp.module')
      .then(m => m.VerifyOTPModule),
  },
  {
    path: 'change-password',
    loadChildren: () => import('./luancher/change-password/change-password.module')
      .then(m => m.ChangePasswordModule),
  },
  {
    path: 'authorize',
    loadChildren: () => import('./luancher/live-view-request/live-view-request.module')
      .then(m => m.LiveViewRequestModule),
  },
    {
      path: '',
      redirectTo: 'login',
      pathMatch: 'full',
    }
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
