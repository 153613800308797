import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-liveview-confirm-request',
  templateUrl: './dialog-liveview-confirm-request.html',
  styleUrls: ['./dialog-liveview-confirm-request.scss']
})
export class DialogLiveviewConfirmRequest {

  @Input() data: any;
  
  constructor(protected ref: NbDialogRef<DialogLiveviewConfirmRequest>) {

  }

  ngOnInit(){
  }

  dismiss(result:any) {
    this.ref.close(result);
  }

}