<div class="modal-content  dialog-modal-card otp-modal">
    <div class="modal-header">
        <h4 class="modal-title">Enter One-Time Passcode</h4>
    </div>
    <form #form="ngForm" class="form-horizontal" novalidate (ngSubmit)="onLogOn(form.value, form.valid)" autocomplete="off">
        <div class="modal-body">
            <p>
                Please click 'Request OTP' button to receive OTP via email.
            </p>            
            <div class="form-group" [ngClass]="{ 'has-error': _isRequestOtp && ((submitted && !pincode.valid) || (pincode.touched && !pincode.valid)) }">
                <label for="pincode" class="control-label">Input Email-OTP Code: </label>   
                <div class="row">
                    <div class="col-md-8">                
                        <pincode length="6" autocomplete="off" id="pincode" autocomplete="off" [disabled]="!_isRequestOtp" name="pincode"
                            focus="false" required [(ngModel)]="logOnAuthentication.pincode" #pincode="ngModel" [focus]="_isRequestOtp"></pincode>
                        <div class="alert alert-danger" *ngIf="_isRequestOtp && ((submitted && !pincode.valid) || (pincode.touched && !pincode.valid))"
                            style="position: absolute; z-index: 100;">
                            <div *ngIf="_isRequestOtp && pincode.errors.invalid">OTP value is incomplete!</div>
                            <div *ngIf="_isRequestOtp && pincode.errors.required">OTP value is required!</div>
                        </div>
                    </div>                
                    <div class="col-md-4 right">
                        <button type="button" class="btn btn-primary confirm-btn" 
                                [disabled]="_isRequestOtp" 
                                (click)="onRequestOtp()" >Request OTP</button>
                    </div>
                </div>
                <div class="row col-md-12 refCode" *ngIf="logOnAuthentication.referenceCode">
                    <div class="otp {{ logOnAuthentication.referenceCode ? 'visible' : 'hidden' }}">
                        Reference Code: <span>{{ logOnAuthentication.referenceCode }}</span>
                    </div>
                </div>
            </div>            
        </div>
        <div class="modal-footer row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6 otp">
                        <div class="col-md-6 timer" *ngIf="clockDisplay">
                            Time: <span>{{ clockDisplay }}</span>
                        </div>
                    </div>
                    <div class="col-md-6 right">
                        <button type="submit" class="btn btn-primary confirm-btn" [disabled]="!_isRequestOtp">Submit</button>
                        <button type="button" class="btn btn-danger confirm-btn" (click)="onExit()">Exit</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- Begin loading overlay-->
<div id="loadingoverlay" class="loadingoverlay" *ngIf="loading">
<div class="spanner">
    <div class="loader-container">
    <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    </div>
</div>
</div>
<!-- End loading overlay-->
<!-- <loading *ngIf="loading" message="Loading..."></loading> -->