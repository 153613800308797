import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { PagesServices } from '../../pages.services';
import { LiveViewServices } from '../../liveview/liveview.services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ngx-dialog-verify-liveview-request',
  templateUrl: './dialog-verify-liveview-request.html',
  styleUrls: ['./dialog-verify-liveview-request.scss']
})
export class DialogVerifyLiveviewRequest {

  @Input() data: any;

  deviceimage:string;
  request:any;
  device:any;

  constructor(protected ref: NbDialogRef<DialogVerifyLiveviewRequest>,
    private _pagesServices : PagesServices,
    private _liveViewServices: LiveViewServices,
    private _toastr: ToastrService) {

  }

  ngOnInit(){
    this.request = this._pagesServices.deviceUsers.filter(q=>q.device == this.data.user)[0];
    this.device = this._pagesServices.deviceUsers.filter(q=>q.device == this.data.device)[0];
    if(this.device.photo){
      this.deviceimage = this.device.photo ;
    }else{
      this.deviceimage="assets/images/no-photo.png";
    }
    this._liveViewServices.CheckIsRequestToLiveExpire(this.data,(res)=>{
      if(this.data.token == res.token){
      }else{
        this.dismiss("CANCEL");
      }
    });
    
  }

  ngOnDestroy(): void {

  }

  Reject(){
    this._toastr.success("Live view request has been rejected successfully.");
    this.dismiss("REJECT");
  }

  AUTHORIZE(){
    this._toastr.success("Live view request has been approved.");
    this.dismiss("AUTHORIZE");
  }

  Close(){
    this.dismiss("CLOSE");
  }

  dismiss(result:any) {
    this.ref.close(result);
  }

}