import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    selector: 'dialog-yesno.component',
    templateUrl: './dialog-yesno.component.html',
    styleUrls: ['./dialog-yesno.component.scss'],
  })
export class DialogYesNoComponent {
  @Input() title: string;
  @Input() body: string;
  @Input() btnAccept: string = 'Yes';
  @Input() btnCancel: string = 'No';
  
  constructor(protected ref: NbDialogRef<DialogYesNoComponent>) {
  }


  dismiss() {
    this.ref.close();
  }

  submit(value) {
    this.ref.close(value);
  }
}

//https://akveo.github.io/nebular/docs/components/dialog/overview#nbdialogservice
//https://github.com/akveo/nebular/tree/master/src/playground/with-layout/dialog