<div class="row pincode" id="pincode" #pincode>
    <span *ngFor="let pincode of pincodeNumbers; let i = index;" class="{{ pincode.touched && !pincode.isValid ? 'has-error' : '' }}">
        <input nbInput type="password" class="form-control" required maxlength="1" autocomplete="off" pattern="\d*"
        attr.id="pincode{{ i }}" attr.next="pincode{{ i + 1 }}"
        [(disabled)]="_disabled"
        [(value)]="pincode.value"
        (blur)="onBlur($event, i)"
        (keyup)="checkPin($event, i)"
        (keydown)="onKeyDown($event)"
        (focus)="onFocus($event, i)" />
    </span>
</div>