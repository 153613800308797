import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    NbCheckboxModule,
    NbInputModule,
    NbCardModule,
    NbDialogModule,
    NbWindowModule,
    NbButtonModule,
    NbSelectModule,
    NbRadioModule,
    NbUserModule
  } from '@nebular/theme';

import { Toggle } from './components/toggle.component';

import { CustomActionViewComponent } from  './smart-table-custom-button-view/custom-action-view.component';
import { CheckboxButtonViewComponent } from './smart-table-custom-button-view/checkbox-button-view.component';
import { StatusButtonViewComponent } from './smart-table-custom-button-view/status-button-view.component';
import { ActivityLogsButtonViewComponent } from './smart-table-custom-button-view/activity-logs-button-view.component';
import { PinCodeComponent } from './components/pin-code.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { Loading } from './components/loading.component';
import { DialogPinLoginComponent } from './modals/dialog-pin-login.component';
import { DialogOTPLogin } from './modals/dialog-otp-login.component';
import { DialogOKComponent } from './modals/dialog-ok.component';
import { DialogChangePasswordComponent} from './modals/dialog-change-password';
import { DialogChangePINComponent } from './modals/dialog-change-pin';
import { FileDownloadComponent } from './file-download/file-download.component';
import { DialogConfirmComponent } from './modals/dialog-confirm.component';
import { DialogYesNoComponent } from './modals/dialog-yesno.component';
import { DialogPinLiveviewRestrictedComponent } from './modals/dialog-pin-liveview-restricted';
import { DialogLiveviewRequestComponent } from './modals/dialog-liveview-request';
import { DialogVerifyLiveviewRequest } from './modals/dialog-verify-liveview-request';
import { DialogLiveviewConfirmRequest } from './modals/dialog-liveview-confirm-request';
import { DialogShareLiveviewRequestComponent } from './modals/dialog-share-liveview-request';
import { DialogValidationComponent } from './modals/dialog-validation.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NbCheckboxModule,
        NbInputModule,
        CKEditorModule,
        NbCardModule,
        NbDialogModule.forChild(),
        NbWindowModule.forChild(),
        NbButtonModule,
        NbSelectModule,
        NbRadioModule,
        NbUserModule
    ],    
    declarations: [
        CustomActionViewComponent,
        CheckboxButtonViewComponent,
        StatusButtonViewComponent,
        ActivityLogsButtonViewComponent,
        Toggle,
        PinCodeComponent,
        Loading,
        DialogPinLoginComponent,
        DialogOTPLogin,
        DialogOKComponent,
        DialogChangePasswordComponent,
        DialogChangePINComponent,
        FileDownloadComponent,
        DialogConfirmComponent,
        DialogYesNoComponent,
        DialogPinLiveviewRestrictedComponent,
        DialogLiveviewRequestComponent,
        DialogVerifyLiveviewRequest,
        DialogLiveviewConfirmRequest,
        DialogShareLiveviewRequestComponent,
        DialogValidationComponent
    ],
    exports: [
        CustomActionViewComponent,
        CheckboxButtonViewComponent,
        StatusButtonViewComponent,
        ActivityLogsButtonViewComponent,
        Toggle,
        PinCodeComponent,
        Loading,
        DialogPinLoginComponent,
        DialogOTPLogin,
        DialogOKComponent,
        DialogChangePasswordComponent,
        DialogChangePINComponent,
        FileDownloadComponent,
        DialogConfirmComponent,
        DialogYesNoComponent,
        DialogPinLiveviewRestrictedComponent,
        DialogLiveviewRequestComponent,
        DialogVerifyLiveviewRequest,
        DialogLiveviewConfirmRequest,
        DialogShareLiveviewRequestComponent,
        DialogValidationComponent
    ],
    providers: [
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }
}