<nb-card class="dialog-modal-card" *ngIf="request">
    <nb-card-header>Live View Request</nb-card-header>
    <nb-card-body>
        <div >
            <p  *ngIf="request">Dispatch User <b>‘{{request.name}} ({{request.device}})’</b> has requested approval to live stream from the bodycam of the following</p>
            <p>user:</p>
            <div class="profile" *ngIf="device">
                <div class="div-img" >
                    <nb-user picture="{{deviceimage}}" onlyPicture size="large"></nb-user>
                </div>
                <div class="div-desc">
                    <p><b>{{device.name}}</b></p>
                    <p><b>{{device.device}}</b></p>
                </div>
            </div>
        </div>
    </nb-card-body>
    <nb-card-footer>
        <div>
            <button nbButton status="success" (click)="AUTHORIZE()">APPROVE</button>
            <button nbButton status="danger" (click)="Reject()">REJECT</button>
            <button nbButton status="danger" *ngIf="request" class="hidden" id="btnClose{{request.device}}" (click)="Close()">CLOSE</button>
            <button nbButton status="danger" *ngIf="request" class="hidden" id="btnClose{{device.device}}" (click)="Close()">CLOSE</button>
        </div>
    </nb-card-footer>
</nb-card>