import { Injectable } from '@angular/core';
import { Http } from '../shared/http-client/http-client.component';
import 'rxjs/add/operator/map';
import { Observable } from "rxjs/Observable";
import { EnvironmentSpecificService } from "../../environment-specific.service";
import { AppSocket } from '../../app.module';
import { ToastrService } from 'ngx-toastr';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class LiveViewServices {

    apiUrl:any;
    appUrl:any;
    developmentEnv:boolean = true;
    constructor(private _http: Http,
        private _envService: EnvironmentSpecificService,
        private _appSocket: AppSocket,
        private _toastr: ToastrService) {
            
        this.apiUrl = this._envService.envSpecific.apiUrl;
        this.appUrl = this._envService.envSpecific.appUrl;
        this.developmentEnv = this._envService.envSpecific.developmentEnv;

    }

    RequestStartStreaming(id){

        this._appSocket.emit("RequestStartStreaming",id);
    }

    RequestStartStreamingByAdmin(id,approver){

        this._appSocket.emit("RequestStartStreamingByAdmin",id,approver);
    }

    RequestStartStreamingByApprover(id,Approver){

        this._appSocket.emit("RequestStartStreamingByAdmin",id,Approver);
    }

    RequestStartStreamingShareLive(id){

        this._appSocket.emit("RequestStartStreamingShareLive",id);
    }

    RequestStopStreaming(id){

        this._appSocket.emit("RequestStopStreaming",id);
    }

    LiveviewDisconnect(){

        this._appSocket.emit("liveviewdisconnect");
        if (this._appSocket.ioSocket._callbacks['$ResponseStartStream'])  this._appSocket.removeListener('ResponseStartStream');
        if (this._appSocket.ioSocket._callbacks['$devicerecordingstop'])  this._appSocket.removeListener('devicerecordingstop');
        if (this._appSocket.ioSocket._callbacks['$devicerecordingstart'])  this._appSocket.removeListener('devicerecordingstart');
        if (this._appSocket.ioSocket._callbacks['$RequestRejectConference'])  this._appSocket.removeListener('RequestRejectConference');
        if (this._appSocket.ioSocket._callbacks['$streammingexceededtrigger'])  this._appSocket.removeListener('streammingexceededtrigger');
        if (this._appSocket.ioSocket._callbacks['$ResponseRestrictedStream'])  this._appSocket.removeListener('ResponseRestrictedStream');
    }

    GetResponseStream = () =>{
        return Observable.create((observer) => {

            if (this._appSocket.ioSocket._callbacks['$ResponseStartStream'])  this._appSocket.removeListener('ResponseStartStream');
            this._appSocket.on('ResponseStartStream', (message) => {
                observer.next({type:'ResponseStartStream',data: JSON.parse(message)});
            });

            if (this._appSocket.ioSocket._callbacks['$streammingexceededtrigger'])  this._appSocket.removeListener('streammingexceededtrigger');
            this._appSocket.on('streammingexceededtrigger', (message) => {
                observer.next({type:'streammingexceededtrigger',data: JSON.parse(message)});
            });
        });
    }

    GetResponseRestrictedStream = () =>{
        return Observable.create((observer) => {

            if (this._appSocket.ioSocket._callbacks['$ResponseRestrictedStream'])  this._appSocket.removeListener('ResponseRestrictedStream');
            this._appSocket.on('ResponseRestrictedStream', (message) => {
                observer.next({type:'ResponseRestrictedStream',data: JSON.parse(message)});
            });
        });
    }

    
    GetDeviceRecordingStart = () =>{
        return Observable.create((observer) => {

            if (this._appSocket.ioSocket._callbacks['$devicerecordingstart'])  this._appSocket.removeListener('devicerecordingstart');
            this._appSocket.on('devicerecordingstart', (message) => {
                observer.next({type:'devicerecordingstart', device:message});
            });
        });
    }

    GetDeviceRecordingStop = () =>{
        return Observable.create((observer) => {

            if (this._appSocket.ioSocket._callbacks['$devicerecordingstop'])  this._appSocket.removeListener('devicerecordingstop');
            this._appSocket.on('devicerecordingstop', (message) => {
                observer.next({type:'devicerecordingstop', device:message});
            });
        });
    }

    GetRequestRejectConference = () =>{
        return Observable.create((observer) => {

            if (this._appSocket.ioSocket._callbacks['$RequestRejectConference'])  this._appSocket.removeListener('RequestRejectConference');
            this._appSocket.on('RequestRejectConference', (message) => {
                observer.next({type:'RequestRejectConference',data: JSON.parse(message)});
            });
        });
    }

    
    GetListRecordingDevices(): Observable<any>{
        
        return this._http.get(this.appUrl + '/api/devices/getdevicerecordinglist').map((response: Response) => {
            
            return response;
        });
    }

    RequestStartConference(id){

        this._appSocket.emit("RequestStartConference",id);
    }


    RequestStopConference(id){

        this._appSocket.emit("RequestStopConference",id);
    }

    RequestPrepareStartConference(streamid,id,callback){
        this._appSocket.emit("PrepareStartConference",streamid,id,callback);
    }

    RequestToLive(data){
        this._appSocket.emit("RequestToLive",JSON.stringify(data));
    }

    CancelRequestToLive(data){
        this._appSocket.emit("CancelRequestToLive",JSON.stringify(data));
    }

    CheckIsRequestToLiveExpire(data,callback){
        this._appSocket.emit("IsRequestToLiveExpire",JSON.stringify(data),callback);
    }

    GetResponseRequestToLive = () =>{
        return Observable.create((observer) => {
            if (this._appSocket.ioSocket._callbacks['$ResponseRequestToLive'])  this._appSocket.removeListener('ResponseRequestToLive');
            this._appSocket.on('ResponseRequestToLive', (message) => {
                observer.next({type:'ResponseRequestToLive',data: JSON.parse(message)});
            });
        });
    }

    onLeaveRequestTOLive(){
        if (this._appSocket.ioSocket._callbacks['$RequestRejectConference'])  this._appSocket.removeListener('RequestRejectConference');
    }

    VerifyShareLiveview(message,callback){
        this._appSocket.emit("verifyshareliveview",message,callback);
    }

    RequestShareLiveView(data,callback){
        this._appSocket.emit("shareliveview",JSON.stringify(data),callback);
    }

    GetStreamingLogs(): Observable<any> {
        return this._http.get(this.apiUrl + '/liveview/getstreaminglogs').map((response: Response) => {
            return response;
        });
    }

    ExportStreamingLogsToExcel(data): Observable<any> {
        const headers = new HttpHeaders()
            .set('Accept', 'application/xlsx; charset=utf-8')
            .set('timezone',Intl.DateTimeFormat().resolvedOptions().timeZone);

        return this._http.post(this.apiUrl + '/liveview/exportstreaminglogstoexcel',data, { headers:headers, observe: 'response', responseType: 'blob' });
    }

    AntAddSnapshortLog(data): Observable<any> {
        return this._http.post(this.appUrl + '/api/ant/addlogtakesnapshot',data).map((response: Response) => {
            return response;
        });
    }

    AntRecording(data): Observable<any> {
        return this._http.post(this.appUrl + '/api/ant/antlivestreamrecording',data).map((response: Response) => {
            return response;
        });
    }

}