<div class="overlay">
    <div class="d-flex align-items-center justify-content-center waiting-upload-overlay">
        <div class="waiting-upload-message">
            <div class="row">
                <div class="col-md-12">
                    <span class="loading-text" *ngIf="title==null">Please wait while file(s) are being prepared to download…</span>
                    <span class="loading-text" *ngIf="title!=null">{{ title }}</span>
                </div>
                <div class="col-md-12 nopadding-right ">
                    <div class="waiting-upload-progress">
                        <div class="progress">
                            <div id="waiting-upload-div-progress" 
                                class="progress-bar active" 
                                role="progressbar" 
                                aria-valuenow="0" 
                                aria-valuemin="0" 
                                aria-valuemax="100" 
                                style="width:0%">
                            </div>
                        </div>
                    </div>
                    <div class="waiting-upload-textprogress" style="display: inline-block">
                        <span id="waiting-upload-div-spanprogress" class="loading-text">0%</span>
                        <input type="hidden" id="waiting-upload-div-inputprogress"/>
                    </div>
                </div>
                <div class="col-md-12">
                    <span class="loading-text-complete">It may take several minutes to complete.</span>
                    <span class="loading-text-donotRefresh">DO NOT refresh page or close the page!</span>
                </div>
            </div>
        </div>
    </div>
</div>
