import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'toggle',
    templateUrl: './toggle.html',
    styleUrls: ['./toggle.scss']
})
export class Toggle {


    @Input() toggleButtonCaption: string = "Show All";
    @Input() toggleStatus: boolean = false;
    @Output() toggleButtonClick = new EventEmitter<any>();


    @Input() showToggleViewButton: boolean = false;
    @Input() toggleViewButtonCaption: string = "";
    @Input() toggleViewButtonStatus: boolean = false;
   

    onToggleButtonClick(event: Event) {
        this.toggleStatus = !this.toggleStatus;
        this.toggleButtonClick.emit(this.toggleStatus);
    }
}
