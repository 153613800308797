import { Injectable } from '@angular/core';
import { Http } from '../shared/http-client/http-client.component';
import 'rxjs/add/operator/map';
import { Observable } from "rxjs/Observable";
import { EnvironmentSpecificService } from "../../environment-specific.service";

@Injectable()
export class UserManagementService {
    userModel:any;
    apiUrl:any="";
    deviceModel:any;
    
    constructor(private _http: Http,private _envService: EnvironmentSpecificService) {
        this.apiUrl = this._envService.envSpecific.apiUrl;
    }

    GetUser(id): Observable<any>{
        return this._http.get(this.apiUrl + '/users/getuser/'+id).map((response: Response) => {
            
            return response;
        });
    }
    
    ChangeUserProfilePassword(data): Observable<any> {

        return this._http.post(this.apiUrl + '/users/changeuserpassword',data).map((response: Response) => {
            
            return response;
        });
    }

    ChangeUserProfilePin(data): Observable<any> {

        return this._http.post(this.apiUrl + '/users/changeuserpin',data).map((response: Response) => {
            
            return response;
        });
    }
    
    GetListUsers(): Observable<any>{
        
        return this._http.get(this.apiUrl + '/users/listuser').map((response: Response) => {
            
            return response;
        });
    }

        
    GetListAdminUsers(): Observable<any>{
        
        return this._http.get(this.apiUrl + '/users/listadminuser').map((response: Response) => {
            
            return response;
        });
    }

    EditUser(data): Observable<any> {

        return this._http.post(this.apiUrl + '/users/edituser',data).map((response: Response) => {
            
            return response;
        });
    }
        
    GetListAllUsers(): Observable<any>{
        
        return this._http.get(this.apiUrl + '/users/listalluser').map((response: Response) => {
            
            return response;
        });
    }

    ChangeUserStatus(is_activate,id): Observable<any>{
        
        var data = {
            "id": id,
            "isactive": is_activate
        }

        return this._http.post(this.apiUrl + '/users/changeuserstatus',data).map((response: Response) => {
            
            return response;
        });
    }

}