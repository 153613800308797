import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss']
})
export class FileDownloadComponent implements OnInit {

  @Input() title = null;

  constructor() { }

  ngOnInit(): void {
  }

}
