import { Component, Input} from '@angular/core';

@Component({
    selector: 'loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class Loading {

    @Input() message: string = "Loading...";
}
