<nb-card class="dialog-modal-card" *ngIf="_userNameList!=null">
    <nb-card-header>Share Live View</nb-card-header>
    <nb-card-body>
        <div>Select Dispatch User:</div>
        <nb-select id="username" [class.pinhidden]="_mode =='admin'" name="username" *ngIf="_userNameList!=null"  #username="ngModel" fullWidth [(ngModel)]="_selectUser" >
            <nb-option *ngFor="let n of _userNameList" [value]="n.device">{{n.name}}({{n.device}})</nb-option>
        </nb-select>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton class="auth" status="success" id="btnSend" (click)="sendRequest()">SHARE</button>
        <button nbButton status="danger" (click)="dismiss()">CANCEL</button>
        <button nbButton status="danger" class="hidden" id="btnClose{{device.device}}" (click)="dismiss()">CLOSE</button>
    </nb-card-footer>
</nb-card>