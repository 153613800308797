<div class="modal-content dialog-modal-card">
    <div class="modal-header">
        <h4 class="modal-title">Live View Restricted</h4>
    </div>
    <form #form="ngForm" class="form-horizontal" novalidate (ngSubmit)="onSubmit(form.value, form.valid)" autocomplete="off">
        <div class="modal-body">
            <p>Authorization is required to proceed with this request. <span *ngIf="_mode !='admin'">Please contact your Dispatch Administrator.</span></p>
            <div class="form-group" [ngClass]="{ 'has-error': (submitted && !pincode.valid) || (pincode.touched && !pincode.valid) }">
                <p class="body-label"  [class.pinhidden]="_mode =='admin'">Select Dispatch Admin User:</p>
                <nb-select id="username" [class.pinhidden]="_mode =='admin'" name="username" *ngIf="_userNameList!=null"  #username="ngModel" fullWidth [(ngModel)]="logOnAuthentication.username" >
                    <nb-option class="liveresticed" *ngFor="let n of _userNameList" [value]="n.username">{{n.name}}({{n.username}})</nb-option>
                </nb-select>
                <p class="body-label"  [class.pinhidden]="_mode =='admin'">Authorization Method:</p>
                <nb-radio-group layout="row" id="mode" name="mode"  [(ngModel)]="_mode"  (valueChange)="changeMode()"  [class.pinhidden]="_mode=='admin'">
                    <nb-radio *ngFor="let n of _modelist" [value]="n.value" [checked]="n.checked">{{n.name}}</nb-radio>
                  </nb-radio-group>
                <p class="body-label" *ngIf="_mode=='pin'">PIN Code:</p>
                <pincode length="6" id="pin" [class.pinhidden]="_mode!='pin'" autocomplete="off" id="pincode" autocomplete="off" name="pincode" focus="{{pinfocus}}" autoFocus  required [(ngModel)]="logOnAuthentication.pincode" #pincode="ngModel"></pincode>
                <div *ngIf="_mode=='pin'">
                    <div class="alert alert-danger pin" *ngIf="(submitted && !pincode.valid) || (pincode.touched && !pincode.valid)" style="position: absolute; z-index: 100;">
                        <div *ngIf="pincode.errors.invalid">PIN code value is incomplete!</div>
                        <div *ngIf="pincode.errors.required">PIN code is required</div>
                    </div>
                </div>
         
            </div>
        </div>
        <div class="modal-footer row">
            <div class="col-md-12">
                <button nbButton class="auth" status="success"  *ngIf="_mode=='pin'" [disabled]="submitted || loading || (pincode.touched && !pincode.valid) || logOnAuthentication.pincode.length == 0 || logOnAuthentication.username.length == 0">AUTHORIZE</button>
                <button nbButton class="auth" status="success"  *ngIf="_mode == 'request'" id="btnSend" (click)="sendRequest()">SEND</button>
                <button nbButton class="auth" status="success"  *ngIf="_mode=='admin'" id="btnSend" (click)="adminRequest()">AUTHORIZE</button>
                <button nbButton status="danger" (click)="dismiss()">CANCEL</button>
                <button nbButton status="danger" class="hidden" id="btnClose{{device.device}}" (click)="dismiss()">CANCEL</button>
            </div>
        </div>
    </form>
</div>

<loading *ngIf="loading" message="Loading..."></loading>