<nb-card class="dialog-modal-card" *ngIf="data!=null">
    <nb-card-header>Live View Request</nb-card-header>
    <nb-card-body>
        <div>
            <p>Selected Dispatch Admin user ‘<b>{{data.admin.name}} ({{data.admin.device}})</b>’ is currently offline.</p>
            <p>Do you want to send the request via email?</p>
        </div>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton status="success" (click)="dismiss('YES')">YES</button>
        <button nbButton status="danger" (click)="dismiss('NO')">NO</button>
        <button nbButton status="danger" class="hidden" id="btnOnline{{data.admin.device}}" (click)="dismiss('NO')">HOTCANCEL</button>
        <button nbButton status="danger" class="hidden" id="btnClose{{data.device.device}}" (click)="dismiss('NO')">HOTCANCEL</button>
    </nb-card-footer>
</nb-card>