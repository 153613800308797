import { Component, Input,ViewChild, OnDestroy } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NbDialogRef } from '@nebular/theme';
import { LuanchertServices } from '../../../luancher/luancher.services';

@Component({
    selector: "dialog-otp-login",
    styleUrls: ["./dialog-otp-login.component.scss"],
    templateUrl: "./dialog-otp-login.component.html",
})
export class DialogOTPLogin  implements OnDestroy{
    @Input() token : string;
    
    _mode: string = 'otp';
    _focus: boolean = false;
    _isRequestOtp: boolean = false;
    loading: boolean = false;

    default_duration: number = 120; 
    duration: number = 120; 
    logOnAuthentication: any = {
        token:"",
        referenceCode:""
    };
    modalCloseAction: any;
    submitted: boolean = false;

    private subscribeInterval: any;
    public clockDisplay: string;

    ngOnDestroy(): void {

        if (this.subscribeInterval) {

            clearInterval(this.subscribeInterval);
        }
    }

    constructor(protected ref: NbDialogRef<DialogOTPLogin>,
        private _luanchertServices:LuanchertServices,
        private _toastr: ToastrService){
    }

   private closeModal(result: any) {
        this.ref.close(result);
    }

    onLogOn(formData: any, isValid: boolean): void {

        if (!isValid) {

            this.submitted = true;
            return;
        }

        let otpLoginAuthentication = {
            token: this.token,
            otptoken: formData.pincode,
            secretkey: this.logOnAuthentication.referenceCode
        };

        this.loading = true;
        
        this._luanchertServices.LoginByOTP(otpLoginAuthentication).subscribe(res => {

            this.loading = false;

            if (res!= null && res.status) {
                this.closeModal(res);
            }else{
                switch(res.message) { 
                    case 'INVALIDOTP':{
                      this._toastr.error("Incorrect OTP! Please try again");
                      break;
                    }
                    case 'MAXFAILEDLOGIN':{
                      this.closeModal({ status:false,message:'MAXFAILEDLOGIN' });
                      break;
                    }
                    case 'INACTIVEUSER':{
                      this.closeModal({ status:false,message:'INACTIVEUSER' });
                      break;
                    }
                    default:{
                      this._toastr.error("Unable to login! An unexpected error occurred.");
                      this.closeModal({ status:false });
                      break;
                    }
                  }

                this.logOnAuthentication.pincode = "";
            }
        });
    }

    onRequestOtp(): void {

        this.submitted = false;

        this._isRequestOtp = false;

        this.loading = true;

        this._luanchertServices.RequestOTP(this.token).subscribe(response => {
            
            this.loading = false;

            if (response) {

                if(response != null && response.status) {

                    this._isRequestOtp = true;

                    this.logOnAuthentication.referenceCode = response.secretkey;

                    this.timerTick();

                    this._toastr.success("OTP is sent to your email successfully!");
                    return;
                }else{
                    this._toastr.success("Unable to create or send OTP! Please try again later");
                }
            }
        });
    }

    onExit() {

        this.closeModal({ status: false });
    }

    formatDisplay(value: number): string {

        let minutes: number = Math.floor(value / 60);
        let seconds: number = (value - minutes * 60);

        return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    }

    private timerTick() {

        this.subscribeInterval = setInterval(() => {

            if (this.duration > 0) {

                this.clockDisplay = this.formatDisplay(--this.duration);

            } else {

                clearInterval(this.subscribeInterval);

                this._isRequestOtp = false;

                this.logOnAuthentication.referenceCode = "";

                this.clockDisplay = null;

                this.duration = this.default_duration;

                this.closeModal({ status: false });
            }

        }, 1000);
    }

    private clearTimer() {

        this.submitted = false;

        this._isRequestOtp = false;

        this.logOnAuthentication.referenceCode = "";

    }

}
