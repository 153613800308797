import { Injectable } from '@angular/core';
import { Http } from '../pages/shared/http-client/http-client.component';
import { Router } from "@angular/router";
import 'rxjs/add/operator/map';
import { EnvironmentSpecificService } from '../environment-specific.service';
@Injectable()
export class ThemeService {
    
    constructor(private _http: Http,
        private _router: Router,
        private _envService: EnvironmentSpecificService) {

    }

    GetUserInfo(){
        return this._http.getCurrentUser();
    }

    LogOut(){
        
        return this._http.get(this._envService.envSpecific.apiUrl+ '/get-start/logout').map((response: Response) => {
            
            this._http.ClearCurrentUser();
            
            return response;
        });
    }

}
