import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { PagesServices } from '../../pages.services';
import { LiveViewServices } from '../../liveview/liveview.services';

@Component({
  selector: 'dialog-liveview-request',
  templateUrl: './dialog-liveview-request.html',
  styleUrls: ['./dialog-liveview-request.scss']
})
export class DialogLiveviewRequestComponent {

  @Input() data: any;

  fullname:string;
  timer:number = 300;
  timerformat: string = "5:00";
  timerinterval:any;
  constructor(
    protected ref: NbDialogRef<DialogLiveviewRequestComponent>,
    private _pagesServices : PagesServices,
    private _liveViewServices: LiveViewServices) {
  }

  ngOnInit(){
    var that = this;
    var device = this._pagesServices.deviceUsers.filter(q=>q.device == this.data.admin)[0];
    this.fullname = device.name + " ("+ device.device +")";
    this._liveViewServices.CheckIsRequestToLiveExpire(this.data,(res)=>{
      if(res.status){
        this.data.token = res.token;
        this.timerinterval = setInterval(function(){
          that.timer--;
          that.timerformat = that.padtime(that.timer);
          if(that.timer < 0){
            that.data.message = 'EXPIRED';
            that.dismiss(that.data.message);
          }
        },1000);
      }else{
        this.dismiss(this.data.message);
      }
    });
    
    this._liveViewServices.GetResponseRequestToLive().subscribe(res=>{
        if(res.data.user == this.data.user && res.data.admin == this.data.admin && res.data.token == this.data.token){
          if(res.data.verify){
            this.closeModal({status: true,admin:this.data.admin});
          }else{
            this.closeModal({status: false,message:'REJECT'});
          }
        }
    });
  }

  ngOnDestroy(): void {
    this._liveViewServices.onLeaveRequestTOLive();
    if(this.timerinterval != null){
      clearInterval(this.timerinterval);
    }
}

  cancel(){
    this.data.message = 'CANCEL';
    this.dismiss(this.data.message );
  }

  exit(){
    this.data.message = 'EXIT';
    this.dismiss('EXIT');
  }

  dismiss(result:any) {
    this._liveViewServices.CancelRequestToLive(this.data);
    this.closeModal({status: false,message:result});

  }

  private closeModal(result: any) {
    this.ngOnDestroy();
    this.ref.close(result);   
  }

  padtime(n){
    var m = parseInt((n / 60).toString());
    var s = parseInt((n % 60).toString());
    var h = parseInt((m / 60).toString());
    if(h > 0){ m = m % 60;}
    var timer = (h > 0 ? (h > 9 ?  h + ":" : "0" + h + ":") :"");
    timer +=  (m > 9 ? m: m);
    timer +=  ":"+(s > 9 ?  s: "0"+ s);
    return timer;
  }

}
