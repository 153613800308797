import { Component,ViewChild, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'dialog-change-pin',
  templateUrl: './dialog-change-pin.html',
  styleUrls: ['./dialog-change-pin.scss'],
})
export class DialogChangePINComponent {

    @ViewChild("form") form: any;

    data: any = {
        password:'',
        pincode:''
    };

    submitted: boolean = false;

    constructor(protected ref: NbDialogRef<DialogChangePINComponent>) {

    }

    dismiss() {
        this.ref.close();
    }

  
    private closeModal(result: any) {
        this.ref.close(result);
    }

    onExit() {

        this.closeModal({ status: false });
    }

    onSubmit(formData: any, isValid: boolean): void {

        if (!isValid ) {

            this.submitted = true;
            return;
        }

        this.closeModal({ status: true , data: { password:formData.password , pincode:formData.pincode  } });
    }
}