<nb-card class="dialog-modal-card" *ngIf="data!=null">
    <nb-card-header>Live View Request</nb-card-header>
    <nb-card-body>
        <div>Please wait for the Dispatch Admin user <b>'{{fullname}}'</b> to authorize the Live View request.</div>
    </nb-card-body>
    <nb-card-footer>
        <div class="timer">{{timerformat}}</div>
        <button nbButton status="danger" (click)="cancel()">CANCEL</button>
        <button nbButton status="danger" class="hidden" id="btnClose{{data.admin}}" (click)="exit()">HOTCANCEL</button>
        <button nbButton status="danger" class="hidden" id="btnClose{{data.device}}" (click)="exit()">HOTCANCEL</button>
    </nb-card-footer>
</nb-card>