<div class="modal-content dialog-modal-card">
    <div class="modal-header">
        <h4 class="modal-title">Change Password</h4>
    </div>
    <form #form="ngForm" class="form-horizontal" novalidate (ngSubmit)="onSubmit(form.value, form.valid)" autocomplete="off">
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <label for="currentpassword">Current Password:</label>
                    <div class="form-group" [ngClass]="{'has-error': currentpassword.touched && currentpassword.errors}">
                        <input type="password" autocomplete="off" class="form-control" id="currentpassword" name="currentpassword" nbInput fullWidth  minlength="8" maxlength="50"
                          placeholder="Current Password" ngModel required #currentpassword="ngModel"  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}" 
                          [(ngModel)]="data.currentpassword" reverse="true" autocomplete="off"/>
                        <div class="input alert alert-danger" *ngIf="(submitted && !currentpassword.valid) || (currentpassword.touched && !currentpassword.valid)">
                          <div *ngIf="currentpassword.errors.required">Current Password is required</div>
                          <div *ngIf="currentpassword.errors.minlength">Current Password should be minimum 8 characters.</div>
                          <div *ngIf="currentpassword && currentpassword.errors.pattern">Current Password must contain minimum one upper case letter, one lower case letter, one number and one special character ! @ # $ % ^ & * ( ) _ +</div>
                        </div>
                      </div>
                </div>
                <div class="col-12">
                    <label for="newpassword">New Password:</label>
                    <div class="form-group" [ngClass]="{'has-error': newpassword.touched && newpassword.errors}">
                        <input type="password" autocomplete="off" class="form-control" id="newpassword" name="newpassword" nbInput fullWidth  minlength="8" maxlength="50"
                          placeholder="New Password" ngModel required #newpassword="ngModel"  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}" 
                          [(ngModel)]="data.newpassword"  reverse="true" autocomplete="off"/>
                        <div class="input alert alert-danger" *ngIf="(submitted && !newpassword.valid) || (newpassword.touched && !newpassword.valid)">
                          <div *ngIf="newpassword.errors.required">New Password is required</div>
                          <div *ngIf="newpassword.errors.minlength">New Password should be minimum 8 characters.</div>
                          <div *ngIf="newpassword && newpassword.errors.pattern">New Password must contain minimum one upper case letter, one lower case letter, one number and one special character ! @ # $ % ^ & * ( ) _ +</div>
                        </div>
                        <div class="input alert alert-danger" *ngIf="!((submitted && !newpassword.valid) || (newpassword.touched && !newpassword.valid)) && (newpassword.value != confirmpassword.value) ">
                          <div *ngIf="(newpassword.value !== confirmpassword.value)">New Password and Confirm Password does not match!</div>
                        </div>
                      </div>
                </div>
                <div class="col-12">
                    <label for="confirmpassword">Confirm Password:</label>
                    <div class="form-group" [ngClass]="{'has-error': confirmpassword.touched && confirmpassword.errors}">
                        <input type="password" autocomplete="off" class="form-control" id="confirmpassword" name="confirmpassword" nbInput fullWidth  minlength="8" maxlength="50"
                          placeholder="Password" ngModel required #confirmpassword="ngModel"  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}" 
                          [(ngModel)]="data.confirmpassword"  reverse="true" autocomplete="off"/>
                        <div class="input alert alert-danger" *ngIf="(submitted && !confirmpassword.valid) || (confirmpassword.touched && !confirmpassword.valid)">
                          <div *ngIf="confirmpassword.errors.required">Confirm Password is required</div>
                          <div *ngIf="confirmpassword.errors.minlength">Confirm Password should be minimum 8 characters.</div>
                          <div *ngIf="confirmpassword && confirmpassword.errors.pattern">Confirm Password must contain minimum one upper case letter, one lower case letter, one number and one special character ! @ # $ % ^ & * ( ) _ +</div>
                        </div>
                        <div class="input alert alert-danger" *ngIf="!((submitted && !confirmpassword.valid) || (confirmpassword.touched && !confirmpassword.valid)) && (newpassword.value != confirmpassword.value) ">
                          <div *ngIf="(newpassword.value !== confirmpassword.value)">New Password and Confirm Password does not match!</div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
        <div class="modal-footer row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
         
                    </div>
                    <div class="col-md-6 right">
                        <button type="submit" nbButton status="primary">Submit</button>
                        <button nbButton status="danger" (click)="onExit()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>