import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalDataSource, ViewCell } from 'ng2-smart-table';

@Component({
    selector: 'action-custom-view',
    template: `
    <div class="actions-button">
    <a class="ng2-smart-action ng2-smart-action-edit-custom" (click)="onClick()" title="{{customTitle}}" *ngIf="!isHideCustomButton">
      <i class="{{ customIcon }}"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-edit-edit" (click)="onEdit()" [ngClass]="{'inActive': (rowData.user_type == 'DISPATCH' && !isAdmin  && userId != rowData.id)}" title="{{editTitle}}" *ngIf="!isHideEditButton">
        <i class="fas fa-pencil-alt"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-delete-delete" (click)="onDelete()" [ngClass]="{'inActive': rowData.status == 'EXPIRED'  || rowData.isDisabled || (rowData.user_type == 'DISPATCH' && !isAdmin  && userId != rowData.id)}" title="{{deleteTitle}}" *ngIf="!isHideDeleteButton">
      <i class="{{ deleteIcon }}"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-shared-shared" [ngStyle]="{'color': rowData.shared ? '#08b4a0' : 'white'}" (click)="onShared()" title="{{sharedTitle}}" *ngIf="!isHideSharedButton">
        <i class="fa fa-share-alt"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-download-download" (click)="onDownload()" title="{{downloadTitle}}" *ngIf="!isHideDownloadButton">
        <i class="fa fa-cloud-download"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-edit-edit" (click)="onDeviceStorage()" [ngClass]="{'inActive': rowData.status.indexOf('red') > 0 }" title="{{customTitle}}" *ngIf="!isHideDeviceStorageButton">
        <i class="fas fas fa-tools"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-edit-edit" (click)="onStorageDownload()" title="{{downloadTitle}}" *ngIf="!isHideStorageDownloadButton">
        <i class="fas fa-download"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-edit-edit" (click)="onCreditCard()" [ngClass]="{'inActive': rowData.status.indexOf('red') > 0 }" title="{{creditcardTitle}}" *ngIf="!isHideCreditCardButton">
        <i class="far fa-credit-card"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-edit-edit" (click)="onShowFile()" [ngClass]="{'inActive': !rowData.status }" title="View Files" *ngIf="!isHidefileButton">
        <i class="fas fa-folder-open"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-edit-edit" (click)="onTerminate()" [ngClass]="{'inActive': !rowData.status }"  [class.disabled]="!rowData.status" title="{{terminateTitle}}" *ngIf="!isHideTerminateButton">
        <i class="fas fa-times-circle"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-edit-edit" (click)="onView()" [ngClass]="{'inActive': rowData.status.indexOf('red') > 0 }" title="{{viewTitle}}" *ngIf="!isHideViewButton">
        <i class="fas fa-eye"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-delete-delete" (click)="onRemove()" title="Delete" *ngIf="!isHideRemoveButton">
        <i class="far fa-trash-alt"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-location-location" (click)="onShowLocation()" title="Show Location" *ngIf="!isHideLocationButton">
        <i class="fa-regular fa-location-crosshairs"></i>
    </a>
  </div>
  `,
    styleUrls: ['./custom-button-view.scss'],
})
export class CustomActionViewComponent implements ViewCell, OnInit {

    editAuthorized: string = "";
    deleteAuthorized: string = "";
    customAuthorized: string = "";
    sharedAuthorized: string = "";
    downloadAuthorized: boolean = false;

    editTitle: string = "Edit";
    deleteTitle: string = "Activate/Deactivate";
    customTitle: string = "Custom";
    sharedTitle: string ="Share";
    downloadTitle: string ="Download";
    creditcardTitle: string = "Payment";
    deviceStorageTitle: string = "Access Device Storage";
    terminateTitle: string = "Terminate";
    viewTitle: string = "View";

    isHideEditButton: boolean = false;
    isHideDeleteButton: boolean = false;
    isHideCustomButton: boolean = true;
    isHideSharedButton: boolean = true;
    isHideDownloadButton: boolean = true;
    isHideDeviceStorageButton: boolean = true;
    isHideCreditCardButton: boolean = true;
    isHideStorageDownloadButton: boolean = true;
    isHideTerminateButton: boolean = true;
    isHideViewButton: boolean = true;
    isHidefileButton: boolean = true;
    isHideRemoveButton: boolean = true;
    isHideLocationButton: boolean = true;
   
    customIcon: string = "ion-edit";
    deleteIcon: string = "fas fa-toggle-on";

    isAdmin: boolean = false;
    userId: string = "";

    @Input() value: any;
    @Input() rowData: any;

    @Output() edit: EventEmitter<any> = new EventEmitter();
    @Output() view: EventEmitter<any> = new EventEmitter();
    @Output() delete: EventEmitter<any> = new EventEmitter();
    @Output() custom: EventEmitter<any> = new EventEmitter();
    @Output() shared: EventEmitter<any> = new EventEmitter();
    @Output() download: EventEmitter<any> = new EventEmitter();
    @Output() terminate: EventEmitter<any> = new EventEmitter();
    @Output() showfile: EventEmitter<any> = new EventEmitter();
    @Output() remove: EventEmitter<any> = new EventEmitter();
    @Output() showLocation: EventEmitter<any> = new EventEmitter();
    ngOnInit() {

        this.editAuthorized = this.value.editAuthorized;
        this.deleteAuthorized = this.value.deleteAuthorized;
        this.sharedAuthorized = this.value.sharedAuthorized;
        this.downloadAuthorized = this.value.downloadAuthorized;

        if (this.value.editTitle) {

            this.editTitle = this.value.editTitle;
        }

        if (this.value.deleteTitle) {

            this.deleteTitle = this.value.deleteTitle;
        }

        if (this.value.hideEditButton) {

            this.isHideEditButton = this.value.hideEditButton;
        }

        if (this.value.hideDeviceStorageButton != undefined) {

            this.isHideDeviceStorageButton = this.value.hideDeviceStorageButton;
        }

        if(this.value.hideCreditCardButton != undefined){ 

            this.isHideCreditCardButton = this.value.hideCreditCardButton
        }
        
        if(this.value.hidefileButton != undefined){ 

            this.isHidefileButton = this.value.hidefileButton
        }

        if(this.value.hideTerminateButton != undefined){ 

            this.isHideTerminateButton = this.value.hideTerminateButton
        }
        
        if(this.value.hideViewButton != undefined){ 

            this.isHideViewButton = this.value.hideViewButton
        }

        if (this.value.hideStorageDownloadButton != undefined) {

            this.isHideStorageDownloadButton = this.value.hideStorageDownloadButton;
        }

        if (this.value.hideDeleteButton) {

            this.isHideDeleteButton = this.value.hideDeleteButton;
        }
        
        if (this.value.hideRemoveButton == false) {

            this.isHideRemoveButton = this.value.hideRemoveButton;
        }

        if (this.value.hideLocationButton == false) {

            this.isHideLocationButton = this.value.hideLocationButton;
        }

        // this.isHideDownloadButton = this.value.hideDownloadButton;

        if (this.value.hideDownloadButton) {
            this.isHideDownloadButton =  this.value.hideDownloadButton;
        }

        if (this.value.customTitle) {

            this.isHideCustomButton = false;

            this.customTitle = this.value.customTitle;
            this.customAuthorized = this.value.customAuthorized;
            this.customIcon = this.value.customIcon;

            if (this.value.custom) {

                this.customIcon = this.value.custom;
            }
        }

        if (this.value.deleteIcon) {

            this.deleteIcon = this.value.deleteIcon;
        }

        if (this.value.sharedTitle) {

            this.isHideSharedButton = false;
        }

        if (this.value.downloadTitle) {

            this.isHideDownloadButton = false;
        }


        var is_admin = JSON.parse(localStorage.getItem("currentUser")).is_admin;
        this.userId = JSON.parse(localStorage.getItem("currentUser")).id;
        if(is_admin != null){
            this.isAdmin = is_admin;
        }
    }

    onEdit() {

        this.edit.emit(this.rowData);
    }

    onView() {

        this.view.emit(this.rowData);
    }

    onDeviceStorage() {

        this.edit.emit(this.rowData);
    }

    onCreditCard() {

        this.edit.emit(this.rowData);
    }

    onTerminate() {

        this.terminate.emit(this.rowData);
    }

    onStorageDownload(){
        
        this.download.emit(this.rowData);
    }
    
    onDelete() {
        this.delete.emit(this.rowData);
    }

    onRemove() {
        this.remove.emit(this.rowData);
    }

    onClick() {

        if (this.checkPermission(this.customAuthorized)) {

            this.custom.emit(this.rowData);
        }
    }

    onShared() {

        if (this.checkPermission(this.sharedAuthorized)) {

            this.shared.emit(this.rowData);
        }
    }

    onDownload() {

        if (this.downloadAuthorized) {

            this.download.emit(this.rowData);
        }
    }

    onShowFile(){
        this.showfile.emit(this.rowData);
    }

    onShowLocation(){
        this.showLocation.emit(this.rowData);
    }

    checkPermission(authorized: any) {

        let currentUser = JSON.parse(localStorage.getItem("currentUser"));

        if (currentUser) {

            let permission = JSON.parse(currentUser.permission);

            if (permission) {

                return permission[authorized];
            }
        }

        return false;
    }
} 
