import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Copyright© WOLFCOM® 2020
    </span>
    <div class="socials">
      <nb-actions size="small">
        <nb-action class="control-item" icon="facebook-outline" (click)="onClick('facebook')"></nb-action>
        <nb-action class="control-item" icon="twitter-outline" (click)="onClick('twitter')"></nb-action>
        <nb-action class="control-item" icon="linkedin-outline" (click)="onClick('linkedin')"></nb-action>
      </nb-actions>
    </div>
  `,
})
export class FooterComponent {

  constructor(private _router: Router){

  }

  onClick(value){
    switch(value){
      case "facebook": window.open('https://www.facebook.com/WolfcomUSA/', '_blank'); break;
      case "twitter": window.open('https://twitter.com/wolfcom_usa/', '_blank'); break;
      case "linkedin": window.open('https://www.linkedin.com/company/wolfcom%C2%AE', '_blank'); break;
    }
  }
}
